<template>
  <div class="common_sub_container">
    <h2 class="sections_to_hide"> POSTAL ENCODE JOB SUMMARY REPORT</h2>
    <section class="sections_to_hide">
      <div class="form_section1">
        <table class="scoped_cell_width_adj">
          <tr>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Choose Period
                  <span></span>
                </label>
                <flat-pickr
                  class="date_for_pos_fixed"
                  v-model="daterange"
                  :config="configDate"
                  placeholder="Date"
                  name="birthdate"
                  @on-close="dateChanged">
                  </flat-pickr>
              </div>
            </td>
            
            <td>
            <!-- <div class="label_and_element_wrapper">
              <label>
                Carrier
                <span></span>
              </label>
                  <select v-model="post_data.provider_id" @change="fetchPostalDocument">
                    <option value="all">All</option>
                    <option
                      v-for="(carrier,i) in carriers"
                      :key="'carriers'+i"
                      :value="carrier.id"
                    >{{carrier.name}}</option>
                  </select>
              </div> -->
              <div class="label_and_element_wrapper">
                <label>
                  GL Code
                  <span></span>
                </label>
                <input type="text" v-model="post_data.code_value">
              </div>
            </td>
            <td>
                <div class="label_and_element_wrapper">
                  <label>
                   Postal Document Type
                    <span></span>
                  </label>
                    <select v-model="post_data.postal_document_type_id">
                      <option value="">All</option>
                      <option v-for="(postaldocument,i) in postaldocuments" :key="'postaldocuments'+i" :value="postaldocument.id">{{postaldocument.postal_document_type}}</option>
                    </select>
                </div>
             
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                 Issued
                  <span></span>
                </label>
                  <select v-model="post_data.issued">
                    <option value="">All</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
              </div>
            </td>
       <!--      <td>
             
                <div class="label_and_element_wrapper">
                  <label>
                    Service Name
                    <span></span>
                  </label>
                  
                  <select v-model="post_data.service_providers_id">
                    <option value="all">All</option>
                    <option
                      v-for="(service,i) in services"
                      :key="'services'+i"
                      :value="service.id"
                    >{{service.name}}</option>
                  </select>
                </div>
            
            </td> -->
           <!-- <td>
              <div class="label_and_element_wrapper">
                <label>
                  Group By
                  <span></span>
                </label>
                <select v-model="post_data.groupby" @change="resetData">
                  <option value="cost_center">Cost Center</option>
                  <option value="carrier_name">Carrier</option>
                  <option value="postal_document_type">Postal Document Type</option>
                  <option value="requester_name">Requester</option>
                </select>
              </div>
            </td> -->
          </tr>
          <tr>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                 Client category
                  <span></span>
                </label>
                  <select v-model="post_data.client_category">
                    <option value="">All</option>
                    <option value="kwm">KWM</option>
                    <option value="base">Base</option>
                  </select>
              </div>
            </td>
            <td v-if="'kwm' == post_data.client_category">
              <div class="label_and_element_wrapper">
                <label>
                 Client Branches
                  <span></span>
                </label>
                  <select v-model="post_data.client_category_branch">
                    <option value="">All</option>
                    <option
                      v-for="(branch,i) in branches"
                      :key="'branches'+i"
                      :value="branch.name"
                    >{{branch.name}}</option>
                  </select>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Matter Number
                  <span></span>
                </label>
                <input type="text" v-model="post_data.matter_number">
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <a class="link_bt bt_save" @click="fetchReport">Search</a>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </table>
      </div>
    </section>
    <section v-if="show_data" class="reports_to_print">
      <div class="despatch_summary_wrapper" v-if="Object.keys(reports).length > 0">
        <header>
          <h3>Postal Encode Summary Report</h3>
          <div class="right_part">
            <a title="Download" ref="download" @click="downloadReport">
              <i class="material-icons">arrow_drop_down_circle</i>
            </a>
            <a title="Print" @click="printRecord">
              <i class="material-icons">print</i>
            </a>
          </div>
        </header>
        <div class="despatch_summary_table_container">
          <table>
            <tr>
              <td><b>Request Date</b></td>
              <td><b>Postal Item</b></td>
              <td><b>Requestor Name</b></td>
              <td><b>Desk No</b></td>
              <td><b>Floor No</b></td>
              <td><b>Issued</b></td>
              <td><b>Issued by</b></td>
              <td><b>Issued date</b></td>
            </tr>
            <tr v-for="(report,i) in reports">
              <td>{{ report.request_date | formatDate }}</td>
              <td>{{ report.postal_document_type ? report.postal_document_type.postal_document_type : '' }}</td>
              <td>{{ report.requester_name  }}</td>

              <template v-if="report.requester_detail">
                <td>{{ report.requester_detail.desk }}</td>
              </template>
              <template v-else-if="report.requester">
                <td>{{report.requester.desk}}</td>
              </template>
              <template v-else>
                <td></td>
              </template>

              <template v-if="report.requester_detail">
                <td>{{ report.requester_detail.floor }}</td>
              </template>
              <template v-else-if="report.requester">
                <td>{{report.requester.floor}}</td>
              </template>
              <template v-else>
                <td></td>
              </template>

              <td>{{ 'Issued' == report.status ?'Y':'N'  }}</td>
              <td>{{ report.issued_by}}</td>
              <td>{{ report.issue_by_date | formatDate }}</td>
            </tr>
          </table>
        </div>
      </div>
      <h4 v-if="Object.keys(reports).length == 0">No data found</h4>
    </section>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";

export default {
  name: "ReportsDelivery",
  components: {
    flatPickr
  },
  data() {
    return {
      daterange: [
        moment(new Date()).format("YYYY-MM-DD"),
        moment(new Date()).format("YYYY-MM-DD")
      ],
      date: null,
      enableTime: true,
      carriers: [],
      services: [],
      postaldocuments: [],
      reports: {},
      branches:[],
      post_data: {
        from: moment(new Date()).format("YYYY-MM-DD"),
        to: moment(new Date()).format("YYYY-MM-DD"),
        provider_id:"",
        service_providers_id:"",
        postal_document_type_id:"",
        code_value:"",
        matter_number:"",
        issued:"",
        client_category:"",
        client_category_branch:"",
      },
      api_url: "",
      configDate: {
        mode: "range",
        format:"d-m-Y",
          altFormat: "d-m-Y",
          altInput: true
      },
       save_data:
      {
        actual_courier_amount:"",
        is_actual_courier_amount:"",
        courier_amount_comment:"",

      },
      show_data: false,
    };
  },
  filters: {
    formatDate(value) {
      if (!value) {
        return;
      }

      return moment(String(value)).format('DD/MM/YYYY')
    },
    formatTime(value) {
      if (!value) {
        return;
      }

      return moment(String(value)).format('LT')
    },
  },
  async created() {
    this.api_url = process.env.VUE_APP_API_URL;
    this.$emit("breadCrumbData", ["Reports", "Postal Encode Summary Report"]);
    await this.fetchCarrier();
    await this.fetchPostalDocument()
    await this.fetchBranch()
    // this.fetchPostalDocument();
  },
  methods: {
    dateChanged(daterange) {
      this.post_data.from = moment(daterange[0]).format("YYYY-MM-DD");
      this.post_data.to = moment(daterange[1]).format("YYYY-MM-DD");
    },
    fetchBranch() {
      this.axios
        .get("/api/branch/view")
        .then(response => {
          this.branches = response.data.branches;
        })
        .catch(error => {
          console.log(error);
        });
    },
    fetchCarrier(){
      this.axios.get("/api/provider/carrierlist")
      .then(response => {
          this.carriers = response.data.providers;
          this.fetchPostalDocument();
          //this.post_data.provider_id = "8e00bfb6b2294c95a0808f8327dd82dd";  
          //this.fetchService();
       
      })
      .catch(error =>{
          this.toast.error();
      });
    },
    fetchPostalDocument(){
      this.axios.get("/api/postaldocument")
      .then(response => {
          this.postaldocuments = response.data.postal_document_types;
      })
      .catch(error =>{
          this.toast.error();
      });
    },
    resetData() {
      this.show_data = false;
      this.reports = {};
    },
    fetchReport() {
      this.axios
        .post("/api/reports/get-postal-issue-encode-report", this.post_data)
        .then(response => {
          this.show_data = true;
          this.reports = response.data.data;
        })
        .catch(error => {
          console.log(e);
        });
    },


    downloadReport() {
      let method = "POST";
      let input;
      let form = document.createElement("form");
      form.setAttribute("method", method);
      form.setAttribute(
        "action",
        this.api_url +
          "/api/reports/get-postal-issue-encode-report/download?token=" +
          localStorage.token
      );
      Object.keys(this.post_data).forEach(item => {
        input = document.createElement("input");
        input.type = "text";
        input.name = item;
        input.value = this.post_data[item];
        form.appendChild(input);
      });
      this.$refs.download.appendChild(form);
      form.submit();
      this.$refs.download.removeChild(form);
    },
    printRecord() {
      window.print();
    }
  }
};
</script>
<style>
.flatpickr-calendar.lol {
  border: 5px solid red;
}
</style>
<style scoped  >
.scoped_cell_width_adj td {
  width: 25%;
}
.link_bt {
  margin-left: 10px;
}
.despatch_summary_wrapper header {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
}
.despatch_summary_wrapper header h3 {
  font-size: 20px;
  font-weight: 600;
}
.despatch_summary_wrapper header .right_part {
  width: auto;
  height: 20px;
}
.despatch_summary_wrapper header .right_part a {
  display: inline-block;
  margin-right: 20px;
}
.despatch_summary_wrapper header .right_part a i {
  font-size: 32px;
}
.despatch_summary_wrapper {
  padding: 20px;
  background: #efefef;
}
.despatch_summary_table_container {
  padding: 20px;
  background: #fff;
  margin-top: 20px;
  min-width: 800px;
  overflow-x: auto;
}
.despatch_summary_table_container table {
  width: 100%;
  border: 1px solid #ddd;
  border-collapse: collapse;
}
.despatch_summary_table_container table td {
  padding: 10px;
  border: 1px solid #ddd;
}
</style>
<style scoped>
@media print {
  .sections_to_hide {
    display: none;
  }
  .reports_to_print {
    display: block;
  }
  .despatch_summary_wrapper {
    padding: 0;
    background: none;
  }
  .despatch_summary_wrapper header .right_part {
    display: none;
  }
  .despatch_summary_table_container {
    padding: 0;
    border: 1px solid #999;
  }
  .despatch_summary_table_container h2 {
    font-size: 16px;
  }
  .despatch_summary_table_container table td {
    border-color: #999;
    font-size: 10px;
  }
  @page {
    size: landscape;
  }
}
</style>